import React from 'react'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const ReferenceModalButton = () => {
  const { setShowComparisonModal } = useDioxinsAndFuransAPI()
  const { selectedSample } = useDioxinsAndFuransContext()
  
  return (
    <button className='text-xs rounded px-2 py-1 cursor-pointer z-0 bg-gray-200 mx-1' onClick={() => setShowComparisonModal(true)}>
      Reference Charts
    </button>
  )
}

export default ReferenceModalButton
