import React from 'react'
import BaseMapContainer from './baseMapContainer'
import FilterContainer from './filterContainer'
import Table from './table'
import Chart from './chart'
import ExportModal from './export/exportModal'

const ReviewPage = () => {
  return (
    <>
      <FilterContainer/>
      <div className="flex h-4/6">
        <div className='relative w-9/12'>
          <BaseMapContainer/>
        </div>
        <div className="w-3/12 bg-green-100">
          <Table/>
        </div>
      </div>
      <div className="h-2/6 overflow-auto">
        <Chart/>
      </div>
      <ExportModal/>
    </>
  )
}

export default ReviewPage
