import axios from 'axios'
import { toPng } from 'html-to-image'

const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/dioxins_and_furans_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'PCDD/F Report.pdf'
  link.click()
}

export const generatePdfReport = async (data, site) => {
  const processedData = await Promise.all(
    data.map(async (item) => {
      const dataUrl =  await toPng(item.report_card, {
        backgroundColor: null,
        filter: (node) => {
          return !(node.tagName === 'svg' && node.getAttribute('data-mapbox-logo') !== null);
        },
        cacheBust: true,
        useCORS: true,
        fontEmbedCSS: false
      });
      return {
        sample_id: item.sample_id,
        report_card: dataUrl
      }
    })
  )
  
  getPdfReportData(site.id, processedData).then((response) => downloadReport(response.data))
}
