export const getColorByType = (type) => {
  switch (type) {
    case 'mono':
      return 'blue'
    case 'di':
      return 'darkorange'
    case 'tri':
      return 'gray'
    case 'tetra':
      return 'gold'
    case 'penta':
      return 'lightblue'
    case 'hexa':
      return 'green'
    case 'hepta':
      return 'purple'
    case 'octa':
      return 'maroon'
    case 'nona':
      return 'darkgray'
    case 'deca':
      return 'red'
    default:
      return 'black'
  }
}

const congenerLegends = (congenerGroups) => {
  return {
    load: function() {
      const fontSize = '12px'
      const color = '#787878'
      const bottomVerticalPosition = 10
      const zIndex = 1000
      const labelSpacing = 100
      const squareSize = 10 
      const legendX = 450

      congenerGroups.forEach((group, index) => {
        const text = `${group.contribution.toFixed(2)}% ${group.group}`
        this.renderer.rect(legendX + (index * labelSpacing), bottomVerticalPosition - (squareSize / 2), squareSize, squareSize)
            .attr({ fill: getColorByType(group.group), zIndex })
            .add()

        this.renderer.text(text, legendX + (index * labelSpacing) + squareSize + 5, bottomVerticalPosition + 4)
            .css({ color, fontSize})
            .attr({ zIndex })
            .add()
      })
    },
  }
}

export const pcbChartOptions = (title, chartType, congeners, showXLabel, maxPcb, inPreview, forExport) => {
  const categories = congeners.map(congener => congener.chemical)
  const chartTypeSum = chartType === 'sum'
  const chartTypeTitle = chartTypeSum ? '% Sum PCB' : '% Homologs'
  const data = congeners.map((congener) => ({
    y: chartTypeSum ? congener.value : congener.biphenyl_value,
    color: getColorByType(congener.type),
    type: congener.type
  }))

  return {
    chart: {
      type: 'column',
      height: inPreview ? (forExport ? 350 : 300) : 230,
      width: forExport ? 1500 : null,
    },
    legend: {
      enabled: false
    },
    title: {
      text: `${chartTypeTitle} | ${title}`,
      useHTML: true,
      align: 'left',
      style: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
        color: 'gray'
      }
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: showXLabel,
        rotation: -90,
        style: {
          fontSize: '7px',
        },
        formatter: function() {
          const maxLength = 10
          if (this.value.length > maxLength) {
            return this.value.substring(0, maxLength) + '...';
          }
          return this.value;
        },
        step: 1
      },
    },
    tooltip: {
      formatter: function() {
        return `<b>${this.x}</b>: ${this.y.toFixed(2)}% ${chartTypeSum ? 'Sum PCB' : this.point.options.type}`
      }
    },
    yAxis: {
      title: {
        text: 'Percentage (%)',
        style: {
          fontSize: '10px'
        }
      },
      min: 0,
      max: maxPcb,
      tickInterval: 2
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        shadow: false,
        animation: false
      }
    },
    series: [{ data: data }],
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    },
    accessibility: { enabled: false }
  }
}

export const congenerContributionsChartOptions = (title, totalGroupCongeners, single, forExport) => {
  const categories = totalGroupCongeners.map(group => group.sample)
  const groupCongenerTypes = totalGroupCongeners[0].groupCongener
  const seriesData = groupCongenerTypes.map(congener => ({
    name: congener.group,
    data: totalGroupCongeners.map(group => group.groupCongener.find(c => c.group === congener.group).contribution),
    color: getColorByType(congener.group)
  }))

  return {
    chart: {
      type: 'bar',
      height: single ? 250 : (forExport ? 600 : 300),
      width: forExport ? 1500 : null,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '8px'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Percentage (%)'
      },
      tickInterval: 20
    },
    legend: {
      enabled: true,
      symbolRadius: 0,
      itemDistance: 2,
      itemStyle: {
        fontSize: single ? '7.5px' : '12px',
        fontWeight: 'normal'
      }
    },
    plotOptions: {
      bar: {
        stacking: 'percent',
      },
      chart:{
        animation: false
      },
      column: {
        stacking: 'percent',
      },
      series: {
        animation: false
      }
    },
    series: seriesData,
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    },
  }
}
