import React, { useMemo, useState} from 'react'
import ExportSampleItem from './exportSampleItem'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'
import { generatePdfReport } from './exportHelpers/exportFunctions'

const PreviewPage = ({setPreview}) => {
  const { site, sampleIdsForExport, exportData } = useDioxinsAndFuransContext()
  const { setShowExportModal, setExportData } = useDioxinsAndFuransAPI()
  const [exporting, setExporting] = useState(false)
  const [samplesProcessed, setSamplesProcessed] = useState(0)
  const isLoaded = sampleIdsForExport.length <= samplesProcessed
  const buttonClass = useMemo(() => {
    if (exporting) return 'bg-red-200 cursor-not-allowed'
    return isLoaded ? 'bg-green-200 hover:bg-green-300 cursor-pointer' : 'bg-gray-100 cursor-not-allowed'
  }, [isLoaded, exporting])
  
  const buttonLabel = useMemo(() => {
    if (exporting) return <p className='flex items-center justify-center'><i className="fa fa-spinner fa-spin mr-2"></i> Exporting..</p>
    return isLoaded ? 'Export as PDF' : <p className='flex items-center justify-center'><i className="fa fa-spinner fa-spin mr-2"></i> Loading..</p>;
  }, [isLoaded, exporting]);
  
  const processed = () => setSamplesProcessed(prev => prev + 1)
  const sampleItems = useMemo(() => sampleIdsForExport.map(sampleId => <ExportSampleItem sampleId={sampleId} siteId={site.id} key={sampleId} processed={processed} />), [sampleIdsForExport, site])
  const handleBack = () => {
    setPreview(false)
    setShowExportModal(true)
    setExportData([])
  }
  
  const handleExport = async () => {
    setExporting(true)
    await generatePdfReport(exportData, site)
    setExporting(false)
  }
  
  return (
    <>
      <div className='overflow-auto'>
        <div className='sticky top-0 flex justify-between px-2 z-20 bg-white border border-black py-1'>
          <button className='border-gray-400 rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24' onClick={handleBack}>Back</button>
          <h1 className='font-semibold uppercase'>Preview Samples</h1>
          <button className={`${buttonClass} rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold w-36 text-center`} onClick={handleExport} disabled={!isLoaded || exporting}>{buttonLabel}</button>
        </div>
        {sampleItems}
      </div>
    </>
  )
}

export default PreviewPage
