import React from 'react'

const ReferenceTable = ({ references, colorMap }) => {
  return (
    <div className='w-full bg-white h-full border border-black'>
      <table className='w-full h-full text-xs bg-gray-100'>
        <thead className='bg-gray-300'>
          <tr>
            <th className='border border-gray-300 p-1 text-center'>Reference Name</th>
            <th className='border border-gray-300 p-1 text-center'>Type</th>
            <th className='border border-gray-300 p-1 text-center'>Similarity</th>
          </tr>
        </thead>
        <tbody>
          {references.map((reference, index) => {
            return (
              <tr key={index} className={`${colorMap[reference.type] || 'bg-gray-200'}`}>
                <td className='font-semibold border border-gray-300 text-left'>{reference.name}</td>
                <td className='border border-gray-300 text-center'>{reference.type.charAt(0).toUpperCase() + reference.type.slice(1)}</td>
                <td className='border border-gray-300 text-center'>{reference.similarity} %</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ReferenceTable
