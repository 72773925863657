import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { pcbChartOptions } from './helpers/chartBuilder'
import NoSample from './noSample'
import SampleExportButton from './export/sampleExportButton'
import BiphenylBar from './biphenylBar'

const Chart = () => {
  const { site, samples, highlightSampleIds, selectedSample, coElutionData, referenceSource } = usePcbContext()
  const { setSelectedSample, setHighlightSampleIds, setPcbCoelutedSample } = usePcbAPI()
  const [congeners, setCongeners] = useState(null)
  const [congenerGroups, setCongenerGroups] = useState(null)
  const [sumPcb, setSumPcb] = useState(null)
  const [maxPcb, setMaxPcb] = useState(null)
  const [chartType, setChartType] = useState('sum')
  const [showXLabel, setShowXLabel] = useState(true)
  const [loading, setLoading] = useState(false)
  const dataLoaded = useMemo(() => sumPcb && congenerGroups && congeners && selectedSample && referenceSource && maxPcb, [sumPcb, congenerGroups, congeners, selectedSample, maxPcb])
  const resetChart = () => {
    setSumPcb(null)
    setCongenerGroups(null)
    setCongeners(null)
    setPcbCoelutedSample(null)
  }

  useEffect(() => {
    if (!highlightSampleIds.length) setSelectedSample(null)
    const sample = samples.find(sample => sample.id === highlightSampleIds[0])
    setSelectedSample(sample)
  },[highlightSampleIds])

  useEffect(() => {
    if (selectedSample?.id) {
      setLoading(true)
      axios.get(`/sites/${site.id}/pcb_fingerprinting_charts?sample_id=${selectedSample?.id}&reference_source=${referenceSource}`)
        .then(res => {
          resetChart()
          const sampleData = res.data.sample
          setCongenerGroups(sampleData.chart.pcb_group_contributions)
          setCongeners(sampleData.chart.chart_data)
          setPcbCoelutedSample(sampleData.raw_data)
          setSumPcb(sampleData.sum)
          
          const maxSamplePcb = Math.max(...sampleData.chart.chart_data.map(congener => congener.value))
          setMaxPcb(Math.ceil(Math.max(maxSamplePcb)))
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
      setHighlightSampleIds([selectedSample.id])
    } else {
      resetChart()
    }
  },[selectedSample, coElutionData, referenceSource])

  const histograms = (title, chartType, congeners, showXLabel, maxPcb, inPreview) => {
    const maxScale = chartType === 'sum' ? maxPcb : Math.ceil(congeners.map(congener => congener.biphenyl_value).reduce((a, b) => Math.max(a, b), 0))
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={pcbChartOptions(title, chartType, congeners, showXLabel, maxScale, inPreview)}
        />
      </div>
    )
  }

  return (
    <div className='flex overflow-auto h-full'>
      {loading ?
        <div className='w-full h-full flex items-center justify-center'>
          <p className='p-2 text-xl font-semibold uppercase text-gray-500'><i className='fa fa-spinner fa-spin'/> Loading...</p>
        </div>
        :
        (dataLoaded ? (
          <div className='flex flex-col w-full'>
            <div className='flex items-center justify-between mb-1 border-2 w-full'>
              <div className='flex items-center'>
                <p className='ml-2 mr-1 font-semibold text-sm'>{selectedSample.sample_name_for_json}</p>
                <SampleExportButton sampleId={selectedSample.id}/>
              </div>
              <div className='w-1/2'>
                <BiphenylBar biphenylMap={congenerGroups} />
              </div>
              <div className='flex items-center'>
                <div className={`flex items-center justify-center border cursor-pointer rounded p-1 ${showXLabel ? 'bg-blue-200 hover:bg-blue-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                  onClick={() => setShowXLabel(!showXLabel)}
                >
                  <label className='ml-2 text-xs font-semibold mr-1'>{showXLabel ? 'Hide Labels' : 'Show Labels'}</label>
                </div>
                <div className={`flex items-center justify-center border cursor-pointer rounded p-1 ${chartType === 'sum' ? 'bg-blue-200 hover:bg-blue-300' : 'bg-gray-200 hover:bg-gray-300'}`}
                  onClick={() => setChartType(chartType === 'sum' ? 'homologs' : 'sum')}
                >
                  <p className='ml-2 text-xs font-semibold mr-1'>{chartType !== 'sum' ? 'Show % Sum PCB' : 'Show % Homologs'}</p>
                </div>
              </div>
            </div>
            {histograms(`${selectedSample.sample_name_for_json} | Sum PCB: ${sumPcb.toFixed(2)}`, chartType, congeners, showXLabel, maxPcb, false)}
          </div>
        )
        : 
        <NoSample />
        )
      }
    </div>
  )
}

export default Chart
