import React from 'react'
import { formatReferenceName } from '../helpers/referenceSourceHelper'

const SampleMetadata = ({ sample, referenceSource }) => {
  return (
    <div className='w-full bg-white h-full border border-black'>
      <p className='font-semibold bg-gray-300 uppercas p-1 text-sm'>Sample Metadata</p>
      <table className='w-full'>
        <tbody className='font-semibold text-xs'>
          <tr className='border'>
            <td className='px-1'>Unique Identifier:</td><td className='font-normal'>{sample?.name || sample.id}</td>
          </tr>
          <tr className='border'>
            <td className='px-1'>Sample Location:</td><td className='font-normal'>{sample.sample_location.name}</td>
          </tr>
          <tr className='border'>
            <td className='px-1'>Date:</td><td className='font-normal'>{sample.sample_date}</td>
          </tr>
          <tr className='border'>
            <td className='px-1'>Matrix:</td><td className='font-normal'>{sample.matrix.title}</td>
          </tr>
          {sample.sample_depth && (
            <tr className='border'>
              <td className='px-1'>Depth:</td><td className='font-normal'>{sample.sample_depth}</td>
            </tr>
          )}
          <tr>
            <td className='px-1'>Reference Source:</td><td className='font-normal'>{formatReferenceName(referenceSource)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SampleMetadata
