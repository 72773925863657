import React from 'react'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const KeySamplesButton = () => {
  const { showKeySampleModal, keySamples } = useDioxinsAndFuransContext()
  const { setShowKeySampleModal } = useDioxinsAndFuransAPI()
  
  return (
    <>
      <button className={`flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase`}
        onClick={() => setShowKeySampleModal(!showKeySampleModal)}>
          Manage Key Samples
      </button>
    </>
  )

}

export default KeySamplesButton
