import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Chart from './chart'
import ExportButton from './exportButton'
import ReferenceModalButton from './referenceModalButton'
import { useDioxinsAndFuransAPI, useDioxinsAndFuransContext } from '../../contexts/dioxinsAndFurans'
import { generateChartOptions } from './chartHelpers/chartFunctions'

const SampleCharts = () => {
  const {site, selectedSample, highlightSampleIds, samples } = useDioxinsAndFuransContext()
  const { setSelectedSample, setHighlightSampleIds, setSelectedSampleExceedanceInfo } = useDioxinsAndFuransAPI()
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!highlightSampleIds.length) return
    const sample = samples.find(sample => sample.id === highlightSampleIds[0])
    setSelectedSample(sample)
  },[highlightSampleIds])

  useEffect(() => {
    axios.get(`/sites/${site.id}/dioxins_and_furans_sample_data?sample_id=${selectedSample.id}`)
      .then(res => {
        setData(res.data.sample_charts)
        setSelectedSampleExceedanceInfo(res.data.exceedance_info)
      })
    setHighlightSampleIds([selectedSample.id])
  },[selectedSample])

  return (
    <div className='px-1'>
      <div className='flex py-1 mx-1'>
        <p className='font-semibold mr-1'>{selectedSample?.sample_name_for_json}</p>
        <ExportButton sampleId={selectedSample.id}/>
        <ReferenceModalButton/>
      </div>
      {data &&
        <div className='flex overflow-auto'>
          <Chart options={generateChartOptions(data?.unstandardized, 'Unstandardized', `Total Homologues: ${data.total_homologues.toPrecision(5)}`, null, '#8B0000', 'linear')} />
          <Chart options={generateChartOptions(data?.sum_2378, '2378 Sum', `Total Homologues: ${data.total_homologues.toPrecision(5)}`, null,  '#3A47A9', 'logarithmic')} />
          <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data.total_dioxins_and_furans.toPrecision(5)}`, null, '#0095ED', 'logarithmic')} />
          <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues', `Total Dioxins ${data.total_dioxins.toPrecision(5)} | Furans ${data.total_furans.toPrecision(5)}`, null, '#49999C', 'logarithmic')} />
          <Chart options={generateChartOptions(data?.relative_teq, 'Relative TEQ', `Total TEQ: ${data.total_analyte.toPrecision(5)}`, null, '#8B008B', 'logarithmic')} />
          <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent', `Total Mole ${data.total_mole.toPrecision(5)}`, null, '#00CACB', 'logarithmic')} />
          <Chart options={generateChartOptions(data?.hexa_cddfs, 'Hexa CDD/Fs', `Total Hexas: ${data.total_hexas.toPrecision(5)}`, null, '#CC5500', 'linear')} />
          <Chart options={generateChartOptions(data?.hexa_penta_cddfs, 'Hexa and Penta CDD/Fs', `Total Hexas: ${data.total_hexas.toPrecision(5)} | Pentas: ${data.total_pentas.toPrecision(5)}`, null, '#B8860B', 'linear')} />
        </div>
      }
    </div>
  )
}

export default SampleCharts
