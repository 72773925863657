import React, { createContext, useReducer, useMemo, useContext } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'

/* Initial States */
const initialState = (initialData) => ({
  geojson: initialData?.data?.geojson,
  samples: initialData?.data?.samples,
  site: initialData.site,
  chemicals: initialData?.chemicals,
  mapType: 'dioxinsAndFurans',
  filteredSamples: null,
  filteredGeojson: null,
  filteredChemical: null,
  highlightSampleIds: [],
  displayedSamples: null,
  showPopups: false,
  showLegends: false,
  selectedSample: initialData.data?.samples[0],
  sampleIdsForExport: [],
  showExportModal: false,
  exportData: [],
  showComparisonModal: false,
  showKeySampleModal: false,
  keySamples: initialData.keyReferences || null,
})

/* Reducer */
const dioxinsAndFuransReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case TOGGLE_POPUPS:
      return { ...state, showPopups: !state.showPopups }
    case TOGGLE_LEGENDS:
      return { ...state, showLegends: !state.showLegends }
    default:
      return state
  }
}

/* Contexts */
const DioxinsAndFuransContext = createContext(initialState)
const DioxinsAndFuransAPI = createContext({
  updateState: () => {},
  setGeoJSON: () => {},
  setSamples: () => {},
  setFilteredSamples: () => {},
  setFilteredGeojson: () => {},
  setFilteredChemical: () => {},
  setHighlightSampleIds: () => {},
  setDisplayedSamples: () => {},
  togglePopups: () => {},
  toggleLegends: () => {},
  setSelectedSample: () => {},
  setSelectedSampleExceedanceInfo: () => {},
  setSampleIdsForExport: () => {},
  setShowExportModal: () => {},
  setExportData: () => {},
  setShowComparisonModal: () => {},
  setShowKeySampleModal: () => {},
  setKeySamples: () => {},
})

/* Providers */
export const DioxinsAndFuransProvider = ({children, initialData}) => {
  const [state, dispatch] = useReducer(dioxinsAndFuransReducer, initialState(initialData))

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value });
    const setGeoJSON = (geojson) => updateState('geojson', geojson);
    const setSamples = (samples) => updateState('samples', samples);
    const setFilteredGeojson = (filteredGeojson) => updateState('filteredGeojson', filteredGeojson);
    const setFilteredSamples = (filteredSamples) => updateState('filteredSamples', filteredSamples);
    const setFilteredChemical = (filteredChemical) => updateState('filteredChemical', filteredChemical);
    const setHighlightSampleIds = (highlightSampleIds) => updateState('highlightSampleIds', highlightSampleIds)
    const setDisplayedSamples = (displayedSamples) => updateState('displayedSamples', displayedSamples)
    const setSelectedSample = (selectedSample) => updateState('selectedSample', selectedSample)
    const setSelectedSampleExceedanceInfo = (selectedSampleExceedanceInfo) => updateState('selectedSampleExceedanceInfo', selectedSampleExceedanceInfo)
    const setShowExportModal = (showExportModal) => updateState('showExportModal', showExportModal)
    const setSampleIdsForExport = (sampleIdsForExport) => updateState('sampleIdsForExport', sampleIdsForExport)
    const setExportData = (exportData) => updateState('exportData', exportData)
    const togglePopups = () => dispatch({ type: TOGGLE_POPUPS })
    const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS })
    const setShowComparisonModal = (showComparisonModal) => updateState('showComparisonModal', showComparisonModal)
    const setShowKeySampleModal = (showKeySampleModal) => updateState('showKeySampleModal', showKeySampleModal)
    const setKeySamples = (keySamples) => updateState('keySamples', keySamples)
    return { setGeoJSON, setSamples, setHighlightSampleIds, togglePopups, toggleLegends, setFilteredGeojson, setFilteredSamples, setFilteredChemical, setDisplayedSamples, setSelectedSample, setSelectedSampleExceedanceInfo, setShowExportModal, setSampleIdsForExport, setExportData, setShowComparisonModal, setShowKeySampleModal, setKeySamples }
  }, [])

  return (
    <DioxinsAndFuransAPI.Provider value={api}>
      <DioxinsAndFuransContext.Provider value={state}>
        {children}
      </DioxinsAndFuransContext.Provider>
    </DioxinsAndFuransAPI.Provider>
  )
}

/* Custom Context Hooks */
export const useDioxinsAndFuransContext = () => useContext(DioxinsAndFuransContext)
export const useDioxinsAndFuransAPI = () => useContext(DioxinsAndFuransAPI)
