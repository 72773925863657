import React, { useState } from "react";
import HomePage from "./homePage";
import PreviewPage from "./previewPage";
import NoData from "../shared/noData";
import ExportModal from "./exportModal";
import ReferenceModal from "./referenceModal";
import KeySamplesModal from "./keySamplesModal";
import { DioxinsAndFuransProvider } from "../../contexts/dioxinsAndFurans";

const Index = ({site, data, chemicals, keyReferences}) => {  
  const [preview, setPreview] = useState(false)

  return (
    <div className='relative flex flex-col h-full'>
      {data ? 
        <DioxinsAndFuransProvider initialData={{site, data, chemicals, keyReferences}}>
          { preview ? <PreviewPage setPreview={setPreview}/> : <HomePage /> }
          <ExportModal setPreview={setPreview}/>
          <ReferenceModal />
          <KeySamplesModal/>
        </DioxinsAndFuransProvider>
        :
        <NoData message="No Dioxins and Furans Data Available" />
      }
    </div>
  )
}

export default Index
