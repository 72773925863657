import React from 'react'
import { colorMap } from './tableHelpers/tableFunctions'

const ReferenceTable = ({cosineTheta, displayedCharts, setDisplayedCharts}) => {
  const handleRefChangeClick = (reference) => {
    const sampleReferences = displayedCharts.filter(chart => chart.type == 'Background' || chart.type == 'Source')
    const newDisplayedCharts = [reference, ...sampleReferences].sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity))
    setDisplayedCharts(newDisplayedCharts)
  }
  
  return (
    <table className='w-full text-xs bg-gray-100'>
      <thead className='bg-gray-200'>
        <tr>
          <th className='border border-gray-300 p-1 text-center'>Reference Name</th>
          <th className='border border-gray-300 p-1 text-center'>Matrix</th>
          <th className='border border-gray-300 p-1 text-center'>Type</th>
          <th className='border border-gray-300 p-1 text-center'>Ref</th>
          <th className='border border-gray-300 p-1 text-center'>DOI</th>
          <th className='border border-gray-300 p-1 text-center'>Similarity</th>
        </tr>
      </thead>
      <tbody>
        {cosineTheta.map((reference, index) => {
          const displayedChartList = displayedCharts.map(chart => chart.name)
          const displayed = displayedChartList.includes(reference.name)
          const rowColor = displayed ? colorMap[reference.type] || 'bg-blue-200' : 'bg-gray-100'
          const hoverStyle = !displayed ? `cursor-pointer hover:bg-blue-100` : 'cursor-default'
          return (
            <tr key={index} className={`${rowColor} ${hoverStyle}`} onClick={() => !displayed && handleRefChangeClick(reference)}>
              <td className='font-semibold border border-gray-300 text-left'>{reference.name}</td>
              <td className='border border-gray-300 text-left'>{reference.primary_matrix} {reference.secondary_matrix ? `- ${reference.secondary_matrix}` : null}</td>
              <td className='border border-gray-300 text-left'>{reference.type}</td>
              <td className='border border-gray-300 text-center'>{reference.abbrev}</td>
              <td className='border border-gray-300 text-center italic px-1'>
                <a 
                  href={reference.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-500 underline"
                >
                  {reference.link ? 'View' : ''}
                </a>
              </td>
              <td className='border border-gray-300 text-center'>{reference.similarity}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ReferenceTable
