import mapboxgl from 'mapbox-gl';

export const removeOldPopups = (popups, setPopups) => {
  popups.forEach(popup => popup.remove());
  setPopups([]);
}

export const buildPopups = (map, geojson, showPopups, showPopupIndex) => {
  const newPopups = [];
  const popupFeatures = geojson?.features
  
  popupFeatures.forEach(feature => {
    const popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
      .setLngLat(feature.geometry.coordinates)
      .setHTML(
        `<div class='h-0.5 w-auto flex items-center bg-gray-300/75'>
          <span class='p-0'>${feature.properties.sample_name || feature.properties.sample_location_name || feature.properties.site_title}</span>
        </div>`
      )
      
    showPopups || showPopupIndex ? popup.addTo(map) : popup.remove();
    
    newPopups.push(popup);
  });
  
  return newPopups;
}

const colorSelector = (color) => {
  switch (color) {
    case '#E2E8F0':
      return 'gray-300';
    case '#fee090':
      return 'yellow-300';
    case '#fc8d59':
      return 'orange-300';
    case '#d73027':
      return 'red-500';
    default:
      return 'green-300';
  }
};
const popup = new mapboxgl.Popup({closeButton: false, closeOnClick: true});

export const removeUnclusteredPopup = () => {
  popup.remove();
}

export const showUnclusteredPointPopup = (map, feature) => {
  const matrix = feature.properties.matrix;
  const coordinates = feature.geometry.coordinates.slice();
  const sampleLocation = feature.properties.sample_name;
  const sampleDepth = feature.properties.depth;
  const sampleDate = feature.properties.sample_date;
  const soilExceedances = feature.properties.soil_exceedance_chemicals || "N/A";
  const groundwaterExceedances = feature.properties.groundwater_exceedance_chemicals || "N/A";
  
  const color = feature.properties.color;
  const sampleValue = feature.properties.value;
  const textColor = color === '#d73027' ? 'text-white' : 'text-black';
  const isSoilBased = matrix === 'Soil' || matrix === 'Subsoil';
  const hasSoilExceedances = soilExceedances.length > 0
  const hasGroundwaterExceedances = groundwaterExceedances.length > 0
  
  const depthInfo = sampleDepth && `<p><span class='font-bold'>Depth: </span>${sampleDepth}</p>`
  const valueInfo = sampleValue && `<p><span class='font-bold'>Value: </span>${sampleValue}</p>`
  const soilExceedanceInfo =`<p><span class='font-bold'>Soil Exceedances: </span>${hasSoilExceedances ? soilExceedances : 'N/A'}</p>`
  const groundwaterExceedanceInfo =`<p><span class='font-bold'>Groundwater Exceedances: </span>${hasGroundwaterExceedances ? groundwaterExceedances : 'N/A'}</p>`
  const exceedanceInfo = isSoilBased ? soilExceedanceInfo : groundwaterExceedanceInfo
  
  const setHTML = () => {
      return `
      <div class='w-auto overflow-x-auto'>
        <div class='text-center'>
          <div class='font-bold uppercase text-xs text-center bg-${colorSelector(color)} ${textColor} rounded-lg py-1 px-2 mb-1'>${sampleLocation}</div>
        </div>
        ${valueInfo || ''}
        ${depthInfo || ''}
        <p><span class='font-bold'>Sample Date: </span>${sampleDate}</p>
        ${exceedanceInfo}
      </div>`
  }
  
  popup.setLngLat(coordinates)
  .setHTML(setHTML())
  .addTo(map);
}

export const showClusteredPopup = (map, features) => {
  const featureInfo = features.map(feature => {
    const hasSoilExceedances = feature.properties.soil_exceedance_chemicals.length > 0;
    const hasGroundwaterExceedances = feature.properties.groundwater_exceedance_chemicals.length > 0;
    
    const matrix = feature.properties.matrix;
    const sampleLocation = feature.properties.sample_name;
    const sampleDate = feature.properties.sample_date;
    const sampleDepth = feature.properties.depth;
    const color = feature.properties.color;
    const soilExceedances = hasSoilExceedances ? feature.properties.soil_exceedance_chemicals : "N/A";
    const groundwaterExceedances = hasGroundwaterExceedances ? feature.properties.groundwater_exceedance_chemicals : "N/A";
    const sampleValue = feature.properties.value;
    return {
      matrix,
      sampleLocation,
      sampleDepth,
      sampleDate,
      color,
      soilExceedances,
      groundwaterExceedances,
      sampleValue
    }
  })

  const htmlContent = featureInfo.map(feature => {
    const exceedanceLabel = feature.matrix === 'Groundwater' ? 'Groundwater Exceedances: ' : 'Soil Exceedances: ';
    const exceedanceChemicals = feature.matrix === 'Groundwater' ? feature.groundwaterExceedances : feature.soilExceedances;
    
    const color = feature.color;
    const textColor = color === '#d73027' ? 'text-white' : 'text-black';
    
    const depthInfo = feature.sampleDepth ? `<p><span class='font-bold text-xs'>Depth: </span>${feature.sampleDepth}</p>` : '';
    const exceedanceInfo = feature.matrix ? `<p><span class='font-bold text-xs'>${exceedanceLabel}</span>${exceedanceChemicals}</p>` : '';
    const valueInfo = feature.sampleValue ? `<p><span class='font-bold text-xs'>Value: </span>${feature.sampleValue}</p>` : '';
    return `
    <div class='mb-2'>
      <span class='font-bold uppercase text-xs bg-${colorSelector(color)} rounded-lg py-1 px-2 mb-1 ${textColor}'>${feature.sampleLocation}</span>
      ${depthInfo}
      ${valueInfo}
      <p><span class='font-bold text-xs'>Sample Date: </span>${feature.sampleDate}</p>
      ${exceedanceInfo}
    </div>
    `
  }).join('');

  popup.setLngLat(features[0].geometry.coordinates)
    .setHTML(`<div class='max-h-44 overflow-y-auto'>${htmlContent}</div>`)
    .addTo(map);
}

export const showClusterChildrenPopup = (map, features, setHighlightSampleIds) => {
  const sampleAnchor = features.map(feature => {
    const handleSampleClick = () => {
      setHighlightSampleIds([feature.properties.sample_id])
      popup.remove()
    }
    const isGroundwater = feature.properties.matrix === 'Groundwater'
    const groundwaterLabel = `<p>${feature.properties.sample_name} - ${feature.properties.sample_date}</p>`
    const soilLabel = `<p>${feature.properties.sample_name} @ ${feature.properties.depth} - ${feature.properties.sample_date}</p>`
    const div = document.createElement('div')
    div.className = 'border bg-gray-100 border-gray-500 uppercase font-semibold text-center text-xs m-1 p-1 rounded-lg hover:bg-gray-300 cursor-pointer'
    div.addEventListener('click', handleSampleClick)
    div.innerHTML = isGroundwater ? groundwaterLabel : soilLabel

    return div
  })

  const popupContent = document.createElement('div')
  popupContent.className = 'flex flex-col'
  sampleAnchor.forEach(anchor => {
    popupContent.appendChild(anchor)
  })

  const popup = new mapboxgl.Popup({ closeButton: false })
    .setLngLat(features[0].geometry.coordinates)
    .setDOMContent(popupContent)

  popup.addTo(map)
}
  
