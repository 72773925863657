import React from 'react'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

const KeySamplesButton = () => {
  const { showKeySampleModal } = usePcbContext()
  const { setShowKeySampleModal } = usePcbAPI()

  return (
    <>
      <button className={`flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase`}
        onClick={() => setShowKeySampleModal(!showKeySampleModal)}>
          Manage Key Samples
      </button>
    </>
  )

}

export default KeySamplesButton
