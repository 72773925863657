import React, { createContext, useReducer, useMemo, useContext } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'

/* Initial States */
const initialState = (initialData) => ({
  geojson: initialData?.data?.geojson,
  samples: initialData?.data?.samples,
  site: initialData.site,
  chemicals: initialData?.chemicals,
  rangeMap: initialData?.rangeMap,
  uploadsData: initialData?.uploadsData,
  mapType: 'pcb',
  filteredSamples: null,
  filteredGeojson: null,
  filteredChemical: null,
  highlightSampleIds: [],
  displayedSamples: null,
  showPopups: false,
  showLegends: false,
  selectedSample: null,
  sampleIdsForExport: [],
  showExportModal: false,
  exportData: [],
  showCoElution: false,
  coElutionData: initialData.coelutions,
  coElutionOptions: initialData.coElutionOptions,
  referenceSource: 'frame',
  pcbCoelutedSample: null,
  showPreview: false,
  showKeySampleModal: false,
  keySamples: initialData.keyReferences || null,
})

/* Reducer */
const pcbReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case TOGGLE_POPUPS:
      return { ...state, showPopups: !state.showPopups }
    case TOGGLE_LEGENDS:
      return { ...state, showLegends: !state.showLegends }
    default:
      return state
  }
}

/* Contexts */
const PcbContext = createContext(initialState)
const PcbAPI = createContext({
  togglePopups: () => {},
  toggleLegends: () => {},
  updateState: () => {},
  setGeoJSON: () => {},
  setSamples: () => {},
  setFilteredSamples: () => {},
  setFilteredGeojson: () => {},
  setFilteredChemical: () => {},
  setHighlightSampleIds: () => {},
  setDisplayedSamples: () => {},
  setSelectedSample: () => {},
  setSampleIdsForExport: () => {},
  setShowExportModal: () => {},
  setExportData: () => {},
  setShowCoElution: () => {},
  setRangeMap: () => {},
  setChemicals: () => {},
  setCoElutionData: () => {},
  setCoElutionOptions: () => {},
  setReferenceSource: () => {},
  setPcbCoelutedSample: () => {},
  setShowPreview: () => {},
  setShowKeySampleModal: () => {},
  setKeySamples: () => {},
})

/* Providers */
export const PcbProvider = ({children, initialData}) => {
  const [state, dispatch] = useReducer(pcbReducer, initialState(initialData))

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value });
    const setGeoJSON = (geojson) => updateState('geojson', geojson);
    const setSamples = (samples) => updateState('samples', samples);
    const setChemicals = (chemicals) => updateState('chemicals', chemicals);
    const setFilteredGeojson = (filteredGeojson) => updateState('filteredGeojson', filteredGeojson);
    const setFilteredSamples = (filteredSamples) => updateState('filteredSamples', filteredSamples);
    const setFilteredChemical = (filteredChemical) => updateState('filteredChemical', filteredChemical);
    const setHighlightSampleIds = (highlightSampleIds) => updateState('highlightSampleIds', highlightSampleIds)
    const setDisplayedSamples = (displayedSamples) => updateState('displayedSamples', displayedSamples)
    const setSelectedSample = (selectedSample) => updateState('selectedSample', selectedSample)
    const setShowExportModal = (showExportModal) => updateState('showExportModal', showExportModal)
    const setSampleIdsForExport = (sampleIdsForExport) => updateState('sampleIdsForExport', sampleIdsForExport)
    const setExportData = (exportData) => updateState('exportData', exportData)
    const togglePopups = () => dispatch({ type: TOGGLE_POPUPS })
    const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS })
    const setShowCoElution = (showCoElution) => updateState('showCoElution', showCoElution)
    const setRangeMap = (rangeMap) => updateState('rangeMap', rangeMap)
    const setCoElutionData = (coElutionData) => updateState('coElutionData', coElutionData)
    const setCoElutionOptions = (coElutionOptions) => updateState('coElutionOptions', coElutionOptions)
    const setReferenceSource = (referenceSource) => updateState('referenceSource', referenceSource)
    const setPcbCoelutedSample = (pcbCoelutedSample) => updateState('pcbCoelutedSample', pcbCoelutedSample)
    const setShowPreview = (showPreview) => updateState('showPreview', showPreview)
    const setShowKeySampleModal = (showKeySampleModal) => updateState('showKeySampleModal', showKeySampleModal)
    const setKeySamples = (keySamples) => updateState('keySamples', keySamples)
    return { setGeoJSON, setSamples, setHighlightSampleIds, togglePopups, toggleLegends, setFilteredGeojson, setFilteredSamples, setFilteredChemical, setDisplayedSamples, setSelectedSample, setShowExportModal, setSampleIdsForExport, setExportData, setShowCoElution, setRangeMap, setChemicals, setCoElutionData, setCoElutionOptions, setReferenceSource, setPcbCoelutedSample, setShowPreview, setShowKeySampleModal, setKeySamples}
  }, [])

  return (
    <PcbAPI.Provider value={api}>
      <PcbContext.Provider value={state}>
        {children}
      </PcbContext.Provider>
    </PcbAPI.Provider>
  )
}

/* Custom Context Hooks */
export const usePcbContext = () => useContext(PcbContext)
export const usePcbAPI = () => useContext(PcbAPI)
