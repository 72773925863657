import React, { useMemo, useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { usePcbAPI, usePcbContext } from '../../contexts/pcb'

const KeySamplesModal = () => {
  const { showKeySampleModal, samples, keySamples, site } = usePcbContext()
  const { setShowKeySampleModal, setKeySamples } = usePcbAPI()
  const [backgroundSample, setBackgroundSample] = useState(null)
  const [sourceSample, setSourceSample] = useState(null)
  const options = useMemo(() => samples.map(sample => ({ value: sample.id, label: sample.sample_name_for_json })), [samples])
  
  useEffect(() => {
    if (keySamples) {
      setBackgroundSample(keySamples.background_sample_id)
      setSourceSample(keySamples.source_sample_id)
    }
  }, [keySamples])
  
  const handleSave = () => {
    axios.post(`/sites/${site.id}/pcb_key_references/create_or_update`, { pcb_key_reference: {site_id: site.id, background_sample_id: backgroundSample, source_sample_id: sourceSample }})
      .then(res => {
        const { background_sample, source_sample } = res.data
        setKeySamples({...keySamples, background_sample_id: background_sample.id, source_sample_id: source_sample.id})
      })
      .catch(err => console.log(err))
    setShowKeySampleModal(false)
  }

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, position: 'absolute', fontSize: '10px' }),
    singleValue: provided => ({
      ...provided,
      fontSize: '10px',
      whiteSpace: 'normal'
    }),
    control: provided => ({
      ...provided,
      width: '350px'
    }),
  }

  return (
    showKeySampleModal && <div className="fixed inset-0 flex items-center justify-center z-20">
      <div className="absolute inset-0" onClick={() => setShowKeySampleModal(false)}></div>
      <div className="bg-white max-w-full h-auto rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
        <div className="relative w-full p-2 overflow-visible">
          <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={() => setShowKeySampleModal(false)}>
            <i className='fa fa-times text-white font-extrabold'/>
          </button>
          <h1 className="text-lg font-semibold text-center">Manage Key Samples</h1>
          <div className='max-h-40 flex flex-col overflow-y-auto p-2'>
            <div className='mb-2'>
              <h1 className="text-sm font-semibold">Background Sample</h1>
              <Select
                className='text-xs'
                placeholder='Select Background Sample'
                options={options}
                defaultValue={options.find(option => option.value === keySamples?.background_sample_id)}
                menuPortalTarget={document.body}
                styles={customStyles}
                onChange={selected => setBackgroundSample(selected.value)}
              />
            </div>
            <div>
              <h1 className="text-sm font-semibold">Source Sample</h1>
              <Select
                className='text-xs'
                placeholder='Select Source Sample'
                options={options}
                defaultValue={options.find(option => option.value === keySamples?.source_sample_id)}
                menuPortalTarget={document.body}
                styles={customStyles}
                onChange={selected => setSourceSample(selected.value)}
              />
            </div>
          </div>
          <div className='flex justify-end items-center p-2'>
            {backgroundSample && sourceSample && <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs mt-3' onClick={handleSave}>Save</button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KeySamplesModal
