import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Chart from './chart'
import Highcharts from "highcharts"
import ReferenceTable from './referenceTable'
import StaticMap from '../shared/staticMap'
import logo from '../../../assets/images/wordmark.png'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import { toPng } from 'html-to-image';
import { generateChartOptions } from './chartHelpers/chartFunctions'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const colorMap = {
  'Background': 'bg-green-200',
  'Source': 'bg-red-200'
}

const downloadReportCard = async (divRef, selectedSample, setLoading) => {
  if (!divRef.current) return;
  setLoading(true)
  try {
    const dataUrl =  await toPng(divRef.current, {
      backgroundColor: null,
      filter: (node) => {
        return !(node.tagName === 'svg' && node.getAttribute('data-mapbox-logo') !== null);
      },
      cacheBust: true,
      useCORS: true,
      fontEmbedCSS: false
    });
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${selectedSample.sample_name_for_json} PAH report.png`;
    link.click();
  } catch (error) {
    console.error('Error capturing divRef as PNG:', error);
  }
  setLoading(false)
};

const ExportSampleItem = ({sampleId, siteId, processed}) => {
  const { samples, exportData, geojson } = useDioxinsAndFuransContext()
  const { setExportData } = useDioxinsAndFuransAPI()
  const [data, setData] = useState(null)
  const [cosineTheta, setCosineTheta] = useState(null)
  const [displayedCharts, setDisplayedCharts] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const sample = samples.find(sample => sample.id === sampleId)
  const divRef = useRef(null)

  useEffect(() => {
    if (data && cosineTheta && displayedCharts) {
      setDataLoaded(true)
      processed()
    }
  },[data, cosineTheta, displayedCharts])

  useEffect(() => {
    axios.get(`/sites/${siteId}/dioxins_and_furans_sample_data?sample_id=${sampleId}`)
      .then(res => {
        setData(res.data.sample_charts)
        const cosineThetaResult = res.data.cosine_theta
        setCosineTheta(cosineThetaResult)
        const backgroundSample = cosineThetaResult.find(ref => ref.type === 'Background') || null
        const sourceSample = cosineThetaResult.find(ref => ref.type === 'Source') || null
        const topReference = cosineThetaResult.filter(ref => ref.type !== 'Background' && ref.type !== 'Source')[0]
        const initialChartsToDisplay = [backgroundSample, sourceSample, topReference]
          .filter(chart => chart !== null)
          .sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        setDisplayedCharts(initialChartsToDisplay)
      })
  },[])
  
  const chartGroup = (displayedCharts, height = null) => {
    return displayedCharts.map((ref, i) => {
      const { chart_data: data } = ref      
      return (
        <div key={`${ref.name}-${i}`} className='border border-black mt-1'>
          <p className={`font-semibold text-sm ${colorMap[ref.type] || 'bg-blue-200'} px-1 sticky left-0`}>{ref?.name} - {ref?.similarity} Similarity</p>
          <div className={`flex justify-center items-center pb-1 ${colorMap[ref.type] || 'bg-blue-200'}`}>
            <Chart options={generateChartOptions(data?.sum_2378, '2378 Sum', `Total Homologues: ${data?.total_homologues.toPrecision(5)}`, height,  '#3A47A9', 'logarithmic', 395)} />
            <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data?.total_dioxins_and_furans.toPrecision(5)}`, height, '#0095ED', 'logarithmic', 395)} />
            <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues', `Total Dioxins ${data?.total_dioxins.toPrecision(5)} | Furans ${data?.total_furans.toPrecision(5)}`, height, '#49999C', 'logarithmic', 395)} />
            <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent', `Total Mole ${data?.total_mole.toPrecision(5)}`, height, '#00CACB', 'logarithmic', 395)} />
          </div>
        </div>
      )
    })
  }
  
  useEffect(() => {
    if (!dataLoaded) return
      const sampleExportData = {
        sample_id: sampleId,
        report_card: divRef.current,
      }
      setExportData([...exportData, sampleExportData])
  },[dataLoaded])
  
  useEffect(() => {
    if (!dataLoaded) return
    const updatedExportData = {
      sample_id: sampleId,
      report_card: divRef.current,
    }
    const exportDataCopy = exportData.filter(exportData => exportData.sample_id !== sampleId)
    setExportData([...exportDataCopy, updatedExportData])
  },[displayedCharts, dataLoaded])
 
  return (
    data &&
    <>
      <div className='flex justify-end mx-1 relative mt-2'>
        <div className='absolute top-5 right-4'>
          <button 
            className={`text-md border-2 border-black p-1 rounded font-semibold flex items-center justify-items-center ${(loading || !dataLoaded) ? 'bg-gray-200 cursor-not-allowed' : 'bg-green-200 hover:bg-green-300'}`}
            onClick={() => downloadReportCard(divRef, sample, setLoading)}
            disabled={loading || !dataLoaded}
          >
            {dataLoaded ? loading ? 'EXPORTING...' : 'EXPORT AS PNG' : 'LOADING...'}
          </button>
        </div>
      </div>
      <div ref={divRef} className= 'border border-b-black rounded mt-2 bg-blue-100 overflow-auto m-2 p-2'>
        <div className='flex items-center mb-2'>
          <img src={logo} alt='logo' className='h-10 mr-2' />
          <p className='font-semibold'>{sample.sample_name_for_json}</p>
        </div>
        <div className='flex justify-between'>
          <div className='w-1/3 h-96'>
            <StaticMap sample={sample} geojson={geojson} />
          </div>
          <div className='w-2/3 h-96 px-1'>
            {cosineTheta && displayedCharts &&
              <div className='w-full text-xs bg-white'>
                <p className='font-semibold text-sm bg-gray-300 px-1'>Sample Metadata</p>
                <table className='w-full'>
                  <tbody>
                    <tr className='border'>
                      <td className='font-semibold px-1'>Location</td>
                      <td>{sample.sample_location.name}</td>
                    </tr>
                    <tr className='border'>
                      <td className='font-semibold px-1'>Date</td>
                      <td>{sample.sample_date}</td>
                    </tr>
                    <tr className='border'>
                      <td className='font-semibold px-1'>Matrix</td>
                      <td>{sample.matrix.title}</td>
                    </tr>
                    <tr className='border'>
                      <td className='font-semibold px-1'>Depth</td>
                      <td>{sample.depth_range ? sample.depth_range : sample.sample_depth || '-'}</td>
                    </tr>
                    <tr className='border'>
                      <td className='font-semibold px-1'>Area</td>
                      <td>{sample.sample_location_group_name || '-'}</td>
                    </tr>
                    <tr className='border'>
                      <td className='font-semibold px-1'>TEQ</td>
                      <td>{data.total_analyte.toPrecision(5) || '-'}</td>
                    </tr>
                  </tbody>
                </table>
                <p className='font-semibold text-sm bg-gray-300 px-1 mt-1'>Cosine Theta Result</p>
                <ReferenceTable cosineTheta={cosineTheta} displayedCharts={displayedCharts} setDisplayedCharts={setDisplayedCharts} />
              </div>
            }
          </div>
        </div>
        <div className='mt-1 flex-col w-full'>
          <div className='border border-black mt-1'>
            <p className='font-semibold text-sm bg-gray-200 px-1'>{sample?.sample_name_for_json}</p>
            <div className='flex justify-center items-center bg-gray-200 p-1'>
              <Chart options={generateChartOptions(data?.sum_2378, '2378 Sum', `Total Homologues: ${data.total_homologues.toPrecision(5)}`, null,  '#3A47A9', 'logarithmic', 395)} />
              <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data.total_dioxins_and_furans.toPrecision(5)}`, null, '#0095ED', 'logarithmic', 395)} />
              <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues', `Total Dioxins ${data.total_dioxins.toPrecision(5)} | Furans ${data.total_furans.toPrecision(5)}`, null, '#49999C', 'logarithmic', 395)} />
              <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent', `Total Mole ${data.total_mole.toPrecision(5)}`, null, '#00CACB', 'logarithmic', 395)} />
            </div>
          </div>
          {cosineTheta && displayedCharts &&
          <>
            {chartGroup(displayedCharts, 290)}
          </>
          }
        </div>
      </div>
    </>
  )
}

export default ExportSampleItem
