import React from 'react'
import { usePcbContext, usePcbAPI } from '../../../contexts/pcb'

const CoElutionButton = () => {
  const { showCoElution } = usePcbContext()
  const { setShowCoElution } = usePcbAPI()
  const buttonColor = showCoElution && 'bg-blue-300'

  return (
    <button className={`text-xs border-2 ${buttonColor} hover:bg-blue-100 border-gray-400 text-gray-500 rounded px-2 py-1 cursor-pointer z-0 uppercase font-semibold ml-1`} onClick={() => setShowCoElution(!showCoElution)}>Manage Coelutions</button>
  )
}

export default CoElutionButton
