import React, { useState, useEffect, useRef }  from 'react'
import axios from 'axios'
import { toPng } from 'html-to-image'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import logo from '../../../assets/images/wordmark.png'
import HC_more from "highcharts/highcharts-more"
import BiphenylBar from './biphenylBar'
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import SampleMetadata from './table/sampleMetadata'
import StaticMap from '../shared/staticMap'
import ReferenceTable from './referenceTable'
import { pcbChartOptions } from './helpers/chartBuilder'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const downloadReportCard = async (divRef, selectedSample, setLoading) => {
  if (!divRef.current) return;
  setLoading(true)
  try {
    const dataUrl =  await toPng(divRef.current, {
      backgroundColor: null,
      filter: (node) => {
        return !(node.tagName === 'svg' && node.getAttribute('data-mapbox-logo') !== null);
      },
      cacheBust: true,
      useCORS: true,
      fontEmbedCSS: false
    });
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${selectedSample.sample_name_for_json} PCB report.png`;
    link.click();
  } catch (error) {
    console.error('Error capturing divRef as PNG:', error);
  }
  setLoading(false)
};

const PreviewChart = ( {sampleId, chartType, processed} ) => {
  const [congeners, setCongeners] = useState(null)
  const [congenerGroups, setCongenerGroups] = useState(null)
  const [sumPcb, setSumPcb] = useState(null)
  const [maxPcb, setMaxPcb] = useState(null)
  const [cosineTheta, setCosineTheta] = useState(null)
  const [congenerChartData, setCongenerChartData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { site, samples, referenceSource, exportData, geojson } = usePcbContext()
  const { setExportData } = usePcbAPI()
  const sample = samples.find(sample => sample.id === sampleId)

  const divRef = useRef(null)
  
  const colorMap = {
    'sample': 'bg-blue-100',
    'background': 'bg-green-200',
    'source': 'bg-red-200',
  }
  
  useEffect(() => {
    if (sumPcb && congenerGroups && congeners && referenceSource && maxPcb && congenerChartData) {
      setDataLoaded(true)
      processed()
    }
  },[sumPcb, congenerGroups, congeners, referenceSource, maxPcb, congenerChartData])
  
  useEffect(() => {
    if (sampleId) {
      axios.get(`/sites/${site.id}/pcb_fingerprinting_charts?sample_id=${sampleId}&reference_source=${referenceSource}`)
        .then(res => {
          const sampleData = res.data.sample
          setCongenerGroups(sampleData.chart.pcb_group_contributions)
          setCongeners(sampleData.chart.chart_data)
          setSumPcb(sampleData.sum)
 
          const cosineThetaResult = res.data.cosine_theta
          setCosineTheta(cosineThetaResult)
          
          const maxSamplePcb = Math.max(...sampleData.chart.chart_data.map(congener => congener.value))
          setMaxPcb(Math.ceil(Math.max(maxSamplePcb)))
          const congenerData = {sample: sample.sample_location.name, groupCongener: res.data.sample.pcb_group_contributions}
          setCongenerChartData([congenerData])
        })
        .catch(err => console.log(err))
    }
  },[])
  
  useEffect(() => {
    if (!dataLoaded) return
      const sampleExportData = {
        sample_id: sampleId,
        report_card: divRef.current,
      }
      setExportData([...exportData, sampleExportData])
  },[dataLoaded])
  
  useEffect(() => {
    if (!dataLoaded) return
    const updatedExportData = {
      sample_id: sampleId,
      report_card: divRef.current,
    }
    const exportDataCopy = exportData.filter(exportData => exportData.sample_id !== sampleId)
    setExportData([...exportDataCopy, updatedExportData])
  },[chartType, dataLoaded])
  
  const histograms = (key, chartTitle, chartType, congeners, showXLabel, maxPcb, inPreview, biphenyls, label, color = 'bg-gray-200') => {
      const maxScale = chartType === 'sum' ? maxPcb : Math.ceil(congeners.map(congener => congener.biphenyl_value).reduce((a, b) => Math.max(a, b), 0))
      return (
        <div key={key} className='mb-1'>
          <div className='flex items-center mb-1 w-full h-10'>
            <div className={`flex items-center w-1/4 border border-black p-1 rounded h-full mr-1 ${color}`}>
              <p className='font-semibold text-xs text-center w-full'>{label}</p>
            </div>
            <div className='w-3/4 h-full'>
              <BiphenylBar biphenylMap={biphenyls}/>
            </div>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={pcbChartOptions(chartTitle, chartType, congeners, showXLabel, maxScale, inPreview)}
          />
        </div>
      )
  }
  
  const referenceHistograms = (cosineTheta) => {
    return cosineTheta.map((ct,i) => {
      const { name, similarity, sum, type } = ct
      const { chart_data, pcb_group_contributions } = ct.chart
      
      return histograms(i, `${name} | Sum PCB ${sum} | Similarity ${similarity} %`, chartType, chart_data, true, maxPcb, false, pcb_group_contributions, name, colorMap[type])
    })
  }

  return (
    dataLoaded ? (
      <>
        <div className='flex flex-col mx-1 relative mt-2'>
          <div className='absolute top-2 right-4'>
            <button 
              className={`text-md border-2 border-black p-1 rounded font-semibold flex items-center justify-items-center ${(loading || !dataLoaded) ? 'bg-gray-200 cursor-not-allowed' : 'bg-green-200 hover:bg-green-300'}`}
              onClick={() => downloadReportCard(divRef, sample, setLoading)}
              disabled={loading || !dataLoaded}
            >
              {dataLoaded ? loading ? 'EXPORTING...' : 'EXPORT AS PNG' : 'LOADING...'}
            </button>
          </div>
          <div ref={divRef} className='p-2 border bg-blue-200 mb-1 rounded'>
            <div className='flex items-center mb-2'>
              <img src={logo} alt='logo' className='h-10 mr-2' />
              <p className='font-semibold ml-1'>{sample.sample_name_for_json}</p>
            </div>
            <div className='mb-2 w-full flex h-64'>
              <div className='w-2/3 h-full'>
                <div className='h-3/5'>
                  <SampleMetadata sample={sample} referenceSource={referenceSource}/>
                </div>
                <div className='h-2/5 mt-1'>
                  <ReferenceTable references={cosineTheta} colorMap={colorMap}/>
                </div>
              </div>
              <div className='w-1/3 h-full ml-1'>
                <StaticMap sample={sample} geojson={geojson}/>
              </div>
            </div>
            <div>
              {histograms('sample', `${sample.sample_name_for_json} | Sum PCB: ${sumPcb.toFixed(2)}`, chartType, congeners, true, maxPcb, false, congenerGroups, sample.sample_name_for_json, colorMap['sample'])}
              {cosineTheta && referenceHistograms(cosineTheta)}
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className='p-1 border bg-blue-200 mb-1 rounded'>
        <div className='w-full h-12 flex items-center justify-center'>
          <p className='text-xl font-semibold text-gray-500'><i className='fa fa-spinner fa-spin'/> {sample?.sample_name_for_json}...</p>
        </div>
      </div>
    )
  )
}

export default PreviewChart
