import React from "react"

const getColorByType = (group) => {
  switch (group) {
    case "mono":
      return "blue"
    case "di":
      return "darkorange"
    case "tri":
      return "gray"
    case "tetra":
      return "gold"
    case "penta":
      return "lightblue"
    case "hexa":
      return "green"
    case "hepta":
      return "purple"
    case "octa":
      return "maroon"
    case "nona":
      return "darkgray"
    case "deca":
      return "red"
    default:
      return "black"
  }
}

const BiphenylTable = ({ biphenylMap }) => {
  return (
    biphenylMap && (
      <div className="flex px-1 rounded justify-center">
        {biphenylMap.map((congener, index) => (
          <div key={index} className="flex items-center mr-2">
            <div
              className="w-2 h-2 rounded-full border border-black"
              style={{ backgroundColor: getColorByType(congener.group) }}
            ></div>
            <p className="ml-1 font-semibold" style={{ fontSize: '10.5px' }}>
              {congener.group} {congener.contribution.toFixed(2) + "%"}
            </p>
          </div>
        ))}
      </div>
    )
  )
}

const BiphenylDivs = ({ biphenylMap }) => {
  const bars = biphenylMap?.map((biphenyl, index) => {
    const { group, contribution } = biphenyl
    const barColor = getColorByType(group)
    return (
      <div
        key={index}
        className="h-2"
        style={{
          backgroundColor: barColor,
          width: `${contribution}%`,
        }}
        title={`${group} ${contribution.toFixed(2)}%`}
      ></div>
    )
  })

  return (
    biphenylMap && (
      <div className="flex border border-black rounded mt-1">{bars}</div>
    )
  )
}

const BiphenylBar = ({ biphenylMap }) => {
  return (
    biphenylMap && (
      <div className="flex flex-col bg-gray-200 p-1 rounded border border-gray-800">
        <BiphenylTable biphenylMap={biphenylMap} />
        <BiphenylDivs biphenylMap={biphenylMap} />
      </div>
    )
  )
}

export default BiphenylBar
