import React, { useEffect, useState } from 'react'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'
import axios from 'axios'
import Chart from './chart'
import { generateChartOptions } from './chartHelpers/chartFunctions'
import ReferenceTable from './referenceTable'
import { colorMap } from './tableHelpers/tableFunctions'

const ReferenceModal = () => {
  const { site, showComparisonModal, selectedSample } = useDioxinsAndFuransContext()
  const { setShowComparisonModal } = useDioxinsAndFuransAPI()
  const [data, setData] = useState(null)
  const [cosineTheta, setCosineTheta] = useState(null)
  const [displayedCharts, setDisplayedCharts] = useState(null)
  
  useEffect(() => {
    axios.get(`/sites/${site.id}/dioxins_and_furans_sample_data?sample_id=${selectedSample.id}`)
      .then(res => {
        setData(res.data.sample_charts)
        
        const cosineThetaResult = res.data.cosine_theta
        setCosineTheta(cosineThetaResult)
        const backgroundSample = cosineThetaResult.find(ref => ref.type === 'Background') || null
        const sourceSample = cosineThetaResult.find(ref => ref.type === 'Source') || null
        const topReference = cosineThetaResult.filter(ref => ref.type !== 'Background' && ref.type !== 'Source')[0]
        const initialChartsToDisplay = [backgroundSample, sourceSample, topReference]
          .filter(chart => chart !== null)
          .sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        setDisplayedCharts(initialChartsToDisplay)
      })
  },[selectedSample])
  
  const chartGroup = (displayedCharts, height = null) => {
    return displayedCharts.map((ref, i) => {
      const { chart_data: data } = ref      
      return (
        <React.Fragment key={`${ref.name}-${i}`}>
          <p className={`font-semibold text-sm ${colorMap[ref.type] || 'bg-blue-200'} px-1 sticky left-0`}>{ref?.name} - {ref?.similarity} Similarity</p>
          <div className='flex w-full'>
            <Chart options={generateChartOptions(data?.unstandardized, 'Unstandardized', `Total Homologues: ${data?.total_homologues.toPrecision(5)}`, height, '#8B0000', 'linear')} />
            <Chart options={generateChartOptions(data?.sum_2378, '2378 Sum', `Total Homologues: ${data?.total_homologues.toPrecision(5)}`, height,  '#3A47A9', 'logarithmic')} />
            <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data?.total_dioxins_and_furans.toPrecision(5)}`, height, '#0095ED', 'logarithmic')} />
            <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues', `Total Dioxins ${data?.total_dioxins.toPrecision(5)} | Furans ${data?.total_furans.toPrecision(5)}`, height, '#49999C', 'logarithmic')} />
            <Chart options={generateChartOptions(data?.relative_teq, 'Relative TEQ', `Total TEQ: ${data?.total_analyte.toPrecision(5)}`, height, '#8B008B', 'logarithmic')} />
            <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent', `Total Mole ${data?.total_mole.toPrecision(5)}`, height, '#00CACB', 'logarithmic')} />
            <Chart options={generateChartOptions(data?.hexa_cddfs, 'Hexa CDD/Fs', `Total Hexas: ${data?.total_hexas.toPrecision(5)}`, height, '#CC5500', 'linear')} />
            <Chart options={generateChartOptions(data?.hexa_penta_cddfs, 'Hexa and Penta CDD/Fs', `Total Hexas: ${data?.total_hexas.toPrecision(5)} | Pentas: ${data?.total_pentas.toPrecision(5)}`, height, '#B8860B', 'linear')} />
          </div>
        </React.Fragment>
      )
    })
  }
  
  return (
    showComparisonModal && <div className="fixed inset-0 flex items-center justify-center z-20">
      <div className="absolute inset-0" onClick={() => setShowComparisonModal(false)}></div>
      <div className="bg-blue-100 w-3/4 h-5/6 rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
        <div className="relative w-full h-full p-2">
          <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={() => setShowComparisonModal(false)}>
            <i className='fa fa-times text-white font-extrabold'/>
          </button>
          <h1 className="text-lg font-semibold text-center">Reference Charts</h1>
          {cosineTheta && displayedCharts &&
            <ReferenceTable cosineTheta={cosineTheta} displayedCharts={displayedCharts} setDisplayedCharts={setDisplayedCharts} />
          }
          <div className='max-h-3/4 w-full flex-col justify-between mt-1 overflow-auto py-2'>
            {data &&
              <>
                <p className='font-semibold text-sm bg-gray-200 px-1 sticky left-0'>{selectedSample?.sample_name_for_json}</p>
                <div className='flex w-full'>
                  <Chart options={generateChartOptions(data?.unstandardized, 'Unstandardized', `Total Homologues: ${data?.total_homologues.toPrecision(5)}`, 240, '#8B0000', 'linear')} />
                  <Chart options={generateChartOptions(data?.sum_2378, '2378 Sum', `Total Homologues: ${data?.total_homologues.toPrecision(5)}`, 240,  '#3A47A9', 'logarithmic')} />
                  <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data?.total_dioxins_and_furans.toPrecision(5)}`, 240, '#0095ED', 'logarithmic')} />
                  <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues', `Total Dioxins ${data?.total_dioxins.toPrecision(5)} | Furans ${data?.total_furans.toPrecision(5)}`, 240, '#49999C', 'logarithmic')} />
                  <Chart options={generateChartOptions(data?.relative_teq, 'Relative TEQ', `Total TEQ: ${data?.total_analyte.toPrecision(5)}`, 240, '#8B008B', 'logarithmic')} />
                  <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent', `Total Mole ${data?.total_mole.toPrecision(5)}`, 240, '#00CACB', 'logarithmic')} />
                  <Chart options={generateChartOptions(data?.hexa_cddfs, 'Hexa CDD/Fs', `Total Hexas: ${data?.total_hexas.toPrecision(5)}`, 240, '#CC5500', 'linear')} />
                  <Chart options={generateChartOptions(data?.hexa_penta_cddfs, 'Hexa and Penta CDD/Fs', `Total Hexas: ${data?.total_hexas.toPrecision(5)} | Pentas: ${data?.total_pentas.toPrecision(5)}`, 240, '#B8860B', 'linear')} />
                </div>
              </>
            }
            {displayedCharts &&
              <>
                {chartGroup(displayedCharts, 240)}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferenceModal
