const getTickPositions = (maxValue, tickCount) => {
  const isBelowOne = maxValue > 1
  const roundTick = (value) => (isBelowOne ? Math.round(value) : Math.round(value * 100) / 100)
  
  return [...Array(tickCount + 1)].map((_, i) => {
    const tickValue = (maxValue / tickCount) * i
    return roundTick(tickValue)
  })
}

export const generateChartOptions = (sampleData, title, subtitle, height= null, color, scale, width= null) => {
  const isMolePercent = title === 'Mole Percent'
  const categories = sampleData.map((data) => data.type ? data.category + ' ' + data.type : data.category)
  const maxValue = Math.max(...sampleData.map((data) => data.y))

  return {
    chart: {
      type: "column",
      width: width || 450,
      height: height || 290,
    },
    title: {
      text: title,
      style: {
        fontSize: '12px',
        fontWeight: 'bold' 
      }
    },
    subtitle: {
      text: subtitle,
      style: {
        fontSize: '11px',
        fontWeight: 'normal', 
        fontColor: 'black'
      }
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -90,
        style: {
          fontSize: '9px'
        },
        staggerLines: 1
      },
    },
    yAxis: {
      title: {
        text: title == 'Unstandardized' ? 'Concentration' : 'Percentage (%)',
      },
      type: scale,
      min: scale === 'logarithmic' ? 0.1 : 0,
      max: scale === 'logarithmic' ? 100 : null,
      tickPosition: scale === 'logarithmic' ? [0.1, 1, 10, 100] : getTickPositions(maxValue, 4),
    },
    plotOptions: {
      series: {
        groupPadding: 0,
        pointPadding: 0,
        animation: false,
      },
    },
    tooltip: {
      formatter: function () {
        return title == 'Unstandardized' ? `${this.x}: ${this.y}` : `${this.x}: ${this.y.toFixed(2)}%`
      },
      followPointer: true,
    },
    series: [
      {
        data: sampleData.map((data) => isMolePercent ? data.y * 100 : data.y),
        color: color || '#7cb5ec',
      },
    ],
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    },
  }
}
