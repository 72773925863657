import React from 'react'
import BaseMap from '../map/baseMap'
import { useDioxinsAndFuransAPI, useDioxinsAndFuransContext } from '../../contexts/dioxinsAndFurans'

const BaseMapContainer = ({ showChart }) => {
  const { geojson, highlightSampleIds, showLegends, showPopups, mapType, filteredGeojson } = useDioxinsAndFuransContext()
  const { setHighlightSampleIds } = useDioxinsAndFuransAPI()
  const currentGeojson = filteredGeojson || geojson

  return (
    <BaseMap 
      geojson={currentGeojson}
      highlightSampleIds={highlightSampleIds}
      setHighlightSampleIds={setHighlightSampleIds}
      showLegends={showLegends}
      showPopups={showPopups}
      mapType={mapType}
      showChart={showChart}
    />
  )
}

export default BaseMapContainer
