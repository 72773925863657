import React, { useState, useMemo } from 'react'
import PreviewChart from './previewChart'
import Highcharts from 'highcharts'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import { generatePdfReport } from './helpers/exportFunctions'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const PreviewPage = () => {
  const { site, sampleIdsForExport, exportData} = usePcbContext()
  const { setShowPreview, setExportData } = usePcbAPI()
  const [chartType, setChartType] = useState('sum')
  const [exporting, setExporting] = useState(false)
  const [samplesProcessed, setSamplesProcessed] = useState(0)
  const isLoaded = sampleIdsForExport.length <= samplesProcessed
  
  const buttonClass = useMemo(() => {
    if (exporting) return 'bg-red-200 cursor-not-allowed'
    return isLoaded ? 'bg-green-200 hover:bg-green-300 cursor-pointer' : 'bg-gray-100 cursor-not-allowed'
  }, [isLoaded, exporting])
  const buttonLabel = useMemo(() => {
    if (exporting) return <p className='flex items-center justify-center'><i className="fa fa-spinner fa-spin mr-2"></i> Exporting..</p>
    return isLoaded ? 'Export as PDF' : <p className='flex items-center justify-center'><i className="fa fa-spinner fa-spin mr-2"></i> Loading..</p>;
  }, [isLoaded, exporting]);

  const processed = () => setSamplesProcessed(prev => prev + 1)
  const sampleList = sampleIdsForExport.map(id => <PreviewChart key={id} sampleId={id} chartType={chartType} processed={processed} />)
  
  const handleBack = () => {
    setShowPreview(false)
    setExportData([])
  }

  const handleExport = async () => {
    setExporting(true)
    await generatePdfReport(exportData, site)
    setExporting(false)
  }

  return (
    <>
      <div className='overflow-auto'>
        <div className='sticky top-0 flex justify-between px-2 z-20 bg-white border border-black py-1'>
          <div className='flex'>
          <button className='border-gray-400 rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24 mr-1' onClick={handleBack}>Back</button>
          <button className={`border-gray-400 rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-36 ${chartType === 'sum' ? 'bg-blue-200 hover:bg-blue-300' : 'bg-green-200 hover:bg-green-300'}`}
            onClick={() => setChartType(chartType === 'sum' ? 'homologs' : 'sum')}
          >
            {chartType === 'sum' ? '% Homologs' : '% Sum PCB'}
          </button>
          </div>
          <h1 className='font-semibold uppercase'>Preview Samples</h1>
          <button className={`${buttonClass} rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold w-36 text-center`} onClick={handleExport} disabled={!isLoaded || exporting}>{buttonLabel}</button>
        </div>
        <div className='p-3'>
          {sampleList}
        </div>
      </div>
    </>
  )
}

export default PreviewPage
